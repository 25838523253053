/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCharmsRoom = /* GraphQL */ `
  query GetCharmsRoom($id: ID!) {
    getCharmsRoom(id: $id) {
      id
      question
      ttl
      charmsResults {
        items {
          id
          roomId
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCharmsRooms = /* GraphQL */ `
  query ListCharmsRooms(
    $filter: ModelCharmsRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCharmsRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        ttl
        charmsResults {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCharmsResults = /* GraphQL */ `
  query GetCharmsResults($id: ID!) {
    getCharmsResults(id: $id) {
      id
      roomId
      ttl
      results {
        id
        x
        y
        rotation
        isDragging
        tokens
      }
      charmsRoom {
        id
        question
        ttl
        charmsResults {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCharmsResultss = /* GraphQL */ `
  query ListCharmsResultss(
    $filter: ModelCharmsResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCharmsResultss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roomId
        ttl
        results {
          id
          x
          y
          rotation
          isDragging
          tokens
        }
        charmsRoom {
          id
          question
          ttl
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
