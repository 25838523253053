import React from 'react'
import { Router } from '@reach/router'
import CharmsRoomTemplate from '../templates/charms-room'
import Layout from '../components/layout'

const Room = () => (
    <Layout>
        <Router basepath="/room">
            <CharmsRoomTemplate path="/*" />
        </Router>
    </Layout>
)

export default Room