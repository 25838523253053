import {useState, useEffect} from 'react'

const useViewport = () => {
    const windowGlobal = typeof window !== `undefined`

        const [width, setWidth] = useState( windowGlobal ? window.innerWidth : 0);

        useEffect(() => {
            if(!windowGlobal) return false
            const handleWindowResize = () => setWidth(window.innerWidth);
            window.addEventListener("resize", handleWindowResize);
            return () => window.removeEventListener("resize", handleWindowResize);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    
        return { width };
  }

export default useViewport;