import React, { useEffect, useReducer, useState, useRef, useContext } from "react"
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useSpring, animated as a } from 'react-spring'
import SEO from "../components/seo"
import '../utils/styles.css'
import Amplify, { API } from 'aws-amplify'
import config from '../aws-exports'
import { v4 as uuid } from 'uuid'
import { listCharmsResultss } from '../graphql/queries'
import { 
    createCharmsResults as CreateCharmsResults, 
  } from '../graphql/mutations'
  import { onCreateCharmsResults } from '../graphql/subscriptions'
import { Stage, Layer, Image, Text, Tag, Label, Group } from 'react-konva';
import Quest from '../components/quest'
import Instructions from '../components/howtoplay'
import { QuestContext } from '../context/questContext'
import Board from '../images/charmsboardv2.png'
import dicerollsfx from '../assets/diceroll.mp3'
import useSound from 'use-sound'
import useImage from 'use-image'
import Block from '../images/BlockCharmsApp.png'
import Calm from '../images/CalmCharmApp.png'
import Clarity from '../images/ClarityAppCharm.png'
import Courage from '../images/CourageAppCharm.png'
import Creativity from '../images/CreativityAppCharm.png'
import FiveIntensity from '../images/IntensityFiveAppCharm.png'
import FourIntensity from '../images/IntensityFourAppCharm.png'
import Gain from '../images/GainCharmsApp.png'
import Gift from '../images/GiftCharmsApp.png'
import GoFive from '../images/GoFiveAppCharm.png'
import GoFour from '../images/GoFourAppCharm.png'
import GoOne from '../images/GoOneAppCharm.png'
import GoSix from '../images/GoSixAppCharm.png'
import GoThree from '../images/GoThreeAppCharm.png'
import GoTwo from '../images/GoTwoAppCharm.png'
import Happy from '../images/HappyCharmApp.png'
import Health from '../images/HealthAppCharm.png'
import Honesty from '../images/HonestyAppCharm.png'
import Illness from '../images/IllnessCharmsApp.png'
import Joy from '../images/JoyAppCharm.png'
import Knowledge from '../images/KnowledgeCharmsApp.png'
import Loss from '../images/LossCharmsApp.png'
import Love from '../images/LoveApp5.png'
import Mad from '../images/MadCharmApp.png'
import Masculine from '../images/MasculineAppCharm.png'
import NotKnowledge from '../images/NotKnowledgeCharmsApp.png'
import OneIntensity from '../images/IntensityOneAppCharm.png'
import Protection from '../images/ProtectionAppCharm.png'
import RightSideOfTheLaw from '../images/WithTheLawAppCharm.png'
import Sadness from '../images/SadCharmApp.png'
import Scared from '../images/ScaredCharmApp.png'
import SeasonFall from '../images/SeasonFallCharm.png'
import SeasonSpring from '../images/SeasonSpringCharm.png'
import SeasonSummer from '../images/SeasonSummerCharm.png'
import SeasonWinter from '../images/SeasonWinterCharm.png'
import SixIntensity from '../images/IntensitySixAppCharm.png'
import StopFive from '../images/StopFiveAppCharm.png'
import StopOne from '../images/StopOneAppCharm.png'
import StopSix from '../images/StopSixAppCharm.png'
import StopThree from '../images/StopThreeAppCharm.png'
import StopTwo from '../images/StopTwoAppCharm.png'
import ThreeIntensity from '../images/IntensityThreeAppCharm.png'
import TravelBike from '../images/TravelBikeCharm.png'
import TravelDrive from '../images/TravelDriveCharm.png'
import TravelFly from '../images/TravelFlyCharm.png'
import TravelSkate from '../images/TravelSkateCharm.png'
import TwoIntensity from '../images/IntensityTwoAppCharm.png'
import Wealth from '../images/WealthAppCharm.png'
import Worried from '../images/WorryCharmApp.png'
import WrongSideOfTheLaw from '../images/WrongSideOfTheLawAppCharm.png'
import Equality from '../images/AntiracismEqualityAppCharm.png'
import Aware from '../images/AwareOpenMindedAppCharm.png'
import Unaware from '../images/UnawareClosedMindedCharmsApp.png'
import Stop4 from '../images/StopFourAppCharm.png'
import Gavel from '../images/JusticeApp5.png'
import Female from '../images/FeminineAppCharm.png'
import BlueGem from '../images/HopeCharmsApp.png'
import Wing from '../images/SpiritualityAppCharm.png'
import useViewport from '../components/useViewport'
import { Twitter, Facebook } from 'react-social-sharing'

Amplify.configure(config)

const charmsDictionary = {
    0: 'block',
    1:{0: 'calm', 1: 'sadness', 2: 'scared', 3: 'worried', 4: 'mad', 5: 'happy'},
    2: 'clarity',
    3: 'courage',
    4: 'creativity',
    5: 'female',
    6:{0: 'oneintensity', 1: 'twointensity', 2: 'threeintensity', 3: 'fourintensity', 4: 'fiveintensity', 5: 'sixintensity'},
    7:{0:'gain', 1:'loss'},
    8: 'gift',
    9:{0: 'goone', 1: 'gotwo', 2: 'gothree', 3: 'gofour', 4: 'gofive', 5: 'gosix'},
    10:{0: 'health', 1:'illness'},
    11: 'honesty',
    12: 'bluegem',
    13: 'joy',
    14: 'gavel',
    15:{0: 'knowledge', 1: 'notknowledge'},
    16: 'love',
    17: 'masculine',
    18: 'protection',
    19:{0: 'rightsideofthelaw', 1: 'wrongsideofthelaw'},
    20:{0: 'seasonfall', 1: 'seasonspring', 2: 'seasonsummer', 3: 'seasonwinter'},
    21: 'wing',
    22:{0: 'stopone', 1: 'stoptwo', 2: 'stopthree', 3: 'stop4', 4: 'stopfive', 5: 'stopsix'},
    23:{0: 'travelbike', 1: 'traveldrive', 2: 'travelfly', 3: 'travelskate'},
    24: 'wealth',
    25: 'equality',
    26:{0: 'aware', 1: 'unaware'}
};

const labelDictionary = {
    'block': 'Block',
    'calm': 'Calm',
    'sadness': 'Sadness',
    'scared': 'Scared',
    'worried': 'Worried',
    'mad': 'Mad',
    'happy': 'Happy',
    'clarity': 'Clarity',
    'courage': 'Courage',
    'creativity': 'Creativity',
    'female': 'Feminine',
    'oneintensity': 'One Intensity',
    'twointensity': 'Two Intensity',
    'threeintensity': 'Three Intensity',
    'fourintensity': 'Four Intensity',
    'fiveintensity': 'Five Intensity',
    'sixintensity': 'Six Intensity',
    'gain': 'Gain',
    'loss': 'Loss',
    'gift': 'Gift',
    'goone': 'Go One',
    'gotwo': 'Go Two',
    'gothree': 'Go Three',
    'gofour': 'Go Four',
    'gofive': 'Go Five',
    'gosix': 'Go Six',
    'health': 'Health',
    'illness': 'Illness',
    'honesty': 'Honesty',
    'bluegem': 'Hope',
    'joy': 'Joy',
    'gavel': 'Justice',
    'knowledge': 'Knowledge',
    'notknowledge': 'Not Knowledge',
    'love': 'Love',
    'masculine': 'Masculine',
    'protection': 'Protection',
    'rightsideofthelaw': 'Right Side of the Law',
    'wrongsideofthelaw': 'Wrong Side of the Law',
    'seasonfall': 'Season Fall',
    'seasonspring': 'Season Spring',
    'seasonsummer': 'Season Summer',
    'seasonwinter': 'Season Winter',
    'wing': 'Spirituality',
    'stopone': 'Stop One',
    'stoptwo': 'Stop Two',
    'stopthree': 'Stop Three',
    'stop4': 'Stop Four',
    'stopfive': 'Stop Five',
    'stopsix': 'Stop Six',
    'travelbike': 'Travel Bike',
    'traveldrive': 'Travel Drive',
    'travelfly': 'Travel Fly',
    'travelskate': 'Travel Skate',
    'wealth': 'Wealth',
    'equality': 'Antiracism, Equality',
    'aware': 'Aware',
    'unaware': 'Unaware'
}

const CharmsRoomTemplate = ({ location }) => {
    const { meanings1, meanings2 } = useStaticQuery(
        graphql`
        query {
          meanings1: file(relativePath: { eq: "KeyCard_Back2.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          meanings2: file(relativePath: { eq: "KeyCard_Front2.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }`
    )
    const [play] = useSound(dicerollsfx)
    const { quest, questModal, setQuestModal, help, setHelp, instructions, setInstructions } = useContext(QuestContext)
    const [tooltip, setTooltip] = useState({})
    const { width } = useViewport()
    const [charmsBoard] = useImage(Board)
    const [flipped, set] = useState(false)
    const [hovering, setHovering] = useState(false)
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 }
    })

    const [block] = useImage(Block)
    const [calm] = useImage(Calm)
    const [clarity] = useImage(Clarity)
    const [courage] = useImage(Courage)
    const [creativity] = useImage(Creativity)
    const [female] = useImage(Female)
    const [oneintensity] = useImage(OneIntensity)
    const [twointensity] = useImage(TwoIntensity)
    const [threeintensity] = useImage(ThreeIntensity)
    const [fourintensity] = useImage(FourIntensity)
    const [fiveintensity] = useImage(FiveIntensity)
    const [sixintensity] = useImage(SixIntensity)
    const [gain] = useImage(Gain)
    const [gift] = useImage(Gift)
    const [goone] = useImage(GoOne)
    const [gotwo] = useImage(GoTwo)
    const [gothree] = useImage(GoThree)
    const [gofour] = useImage(GoFour)
    const [gofive] = useImage(GoFive)
    const [gosix] = useImage(GoSix)
    const [happy] = useImage(Happy)
    const [health] = useImage(Health)
    const [bluegem] = useImage(BlueGem)
    const [honesty] = useImage(Honesty)
    const [illness] = useImage(Illness)
    const [joy] = useImage(Joy)
    const [gavel] = useImage(Gavel)
    const [knowledge] = useImage(Knowledge)
    const [notknowledge] = useImage(NotKnowledge)
    const [loss] = useImage(Loss)
    const [love] = useImage(Love)
    const [mad] = useImage(Mad)
    const [masculine] = useImage(Masculine)
    const [protection] = useImage(Protection)
    const [rightsideofthelaw] = useImage(RightSideOfTheLaw)
    const [wrongsideofthelaw] = useImage(WrongSideOfTheLaw)
    const [sadness] = useImage(Sadness)
    const [scared] = useImage(Scared)
    const [seasonfall] = useImage(SeasonFall)
    const [seasonspring] = useImage(SeasonSpring)
    const [seasonsummer] = useImage(SeasonSummer)
    const [seasonwinter] = useImage(SeasonWinter)
    const [wing] = useImage(Wing)
    const [stopone] = useImage(StopOne)
    const [stoptwo] = useImage(StopTwo)
    const [stopthree] = useImage(StopThree)
    const [stop4] = useImage(Stop4)
    const [stopfive] = useImage(StopFive)
    const [stopsix] = useImage(StopSix)
    const [travelbike] = useImage(TravelBike)
    const [traveldrive] = useImage(TravelDrive)
    const [travelfly] = useImage(TravelFly)
    const [travelskate] = useImage(TravelSkate)
    const [wealth] = useImage(Wealth)
    const [worried] = useImage(Worried)
    const [equality] = useImage(Equality)
    const [aware] = useImage(Aware)
    const [unaware] = useImage(Unaware)

    const charmsValues = {
        'block': block,
        'calm': calm,
        'clarity': clarity,
        'courage': courage,
        'creativity': creativity,
        'female': female,
        'oneintensity': oneintensity,
        'twointensity': twointensity,
        'threeintensity': threeintensity,
        'fourintensity': fourintensity,
        'fiveintensity': fiveintensity,
        'sixintensity': sixintensity,
        'gain': gain,
        'gift': gift,
        'goone': goone,
        'gotwo': gotwo,
        'gothree': gothree,
        'gofour': gofour,
        'gofive': gofive,
        'gosix': gosix,
        'happy': happy,
        'health': health,
        'bluegem': bluegem,
        'honesty': honesty,
        'illness': illness,
        'joy': joy,
        'gavel': gavel,
        'knowledge': knowledge,
        'notknowledge': notknowledge,
        'loss': loss,
        'love': love,
        'mad': mad,
        'masculine': masculine,
        'protection': protection,
        'rightsideofthelaw': rightsideofthelaw,
        'wrongsideofthelaw': wrongsideofthelaw,
        'sadness': sadness,
        'scared': scared,
        'seasonfall': seasonfall,
        'seasonspring': seasonspring,
        'seasonsummer': seasonsummer,
        'seasonwinter': seasonwinter,
        'wing': wing,
        'stopone': stopone,
        'stoptwo': stoptwo,
        'stopthree': stopthree,
        'stop4': stop4,
        'stopfive': stopfive,
        'stopsix': stopsix,
        'travelbike': travelbike,
        'traveldrive': traveldrive,
        'travelfly': travelfly,
        'travelskate': travelskate,
        'wealth': wealth,
        'worried': worried,
        'equality': equality,
        'aware': aware,
        'unaware': unaware
    }

    const generateCharms = () => {
        let rando = ''
        const keys = Object.keys(charmsDictionary);
        let charmsSet = new Set();
        let storedKeys = new Set();
        charmsSet.add('')
        while(charmsSet.size < 10) {
            let objectKey = keys.length * Math.random() << 0;
            rando = charmsDictionary[keys[objectKey]];
            if(typeof rando === 'object') {
                rando = Object.values(rando)[Math.floor(Math.random()*Object.keys(rando).length)];
                if(storedKeys.has(objectKey)){
                    rando = '';
                }
                storedKeys.add(objectKey);
                charmsSet.add(rando)
            } else {
                charmsSet.add(rando)
            }
        };
        charmsSet.delete('')
        let final = Array.from(charmsSet)
        return [...Array(9)].map((_, i) => ({
          id: i.toString(),
          x: (Math.random() * (DestkopWidth)),
          y: (Math.random() * (DestkopHeight)),
          rotation: Math.random() * 180,
          isDragging: false,
          tokens: final[i]
        }));
      };

    const initialState = {
        results: [{
            id: '',
            x: 0,
            y: 0,
            rotation: 0,
            isDragging: false,
            tokens: '',
            label: ''
        }],
        error: false,
        loading: true,
        copiedUrl: false,
        form: { name: '' }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    function reducer(state, action) {
        switch(action.type) {
            case 'SET_BOARD':
                return { ...state, results: action.results, loading: false}
            case 'ROLL_CHARMS':
                return { ...state, results: action.result.results}
            case 'DRAG_START':
                return { ...state, results: [...state.results.map((charm) => {return {...charm, isDragging: charm.id === action.id}})]}
            case 'UPDATE_POSITION':
                const newArray = [...state.results]                          
                newArray[state.results.findIndex(element => element.id === action.id)] = {...newArray[state.results.findIndex(element => element.id === action.id)], x: action.x, y: action.y}
                return {...state, results: newArray}
            case 'DRAG_END': 
                return { ...state, results: [...state.results.map((charm) => {return {...charm, isDragging: false}})]}
            case 'ERROR':
                return { ...state, loading: false, error: true }
            case 'COPY_URL':
                return { ...state, copiedUrl: true}
            default:
                return state
        }
    }

    useEffect(() => {
        const fetchCharmsResults = async () => {
            try {
                const charmsData = await API.graphql({
                    query: listCharmsResultss,
                    variables: {filter: {roomId: {eq: location.pathname.slice(6)}}}
                })
                dispatch({ type: 'SET_BOARD', results: charmsData.data.listCharmsResultss.items })
            } catch(err) {
                console.log('error: ', err)
                dispatch({ type: 'ERROR' })
            }
        }
        fetchCharmsResults();
        const subscription = API.graphql({
            query: onCreateCharmsResults
        })
        .subscribe({
            next: charmsData => {
                const result = charmsData.value.data.onCreateCharmsResults
                if (location.pathname.slice(6) !== result.roomId) return;
                dispatch({ type: 'ROLL_CHARMS', result})
                console.log('charms subscription', charmsData)
            }
        })
        return () => subscription.unsubscribe()
    }, [location])

    async function createCharmsResults() {
        try {
            if(quest === '') {
                setQuestModal(true)
                return;
            }
            let charmsRoll = await generateCharms();
            await play();
            
            const result = await {
                id: uuid(),
                results: charmsRoll,
                roomId: location.pathname.slice(6)
            }
    
            await dispatch({ type: 'ROLL_CHARMS', result})
            console.log('roll result', result)
            await API.graphql({
                query: CreateCharmsResults,
                variables: { input: result }
            })
            console.log("successful charms roll!")
        } catch (err) {
            console.log('error: ', err)
        }
    }

    const DestkopWidth = 1000;
    const DestkopHeight = 1000;

    const scale = Math.min(
        width / DestkopWidth,
        width / DestkopHeight
    );

    const handleDragStart = (e) => {
      const id = e.target.id();
      dispatch({ type: 'DRAG_START', id })
    };
    
    const handleDragEnd = (e) => {
      const x = e.target.x() / scale; 
      const y = e.target.y() / scale;
      const id = e.target.id();
      dispatch({ type: 'UPDATE_POSITION', id, x, y })
      dispatch({ type: 'DRAG_END'})
    };

    const beginHover = (e) => {
        const charm = {
            name: e.target.attrs.label,
            x: e.target.attrs.x,
            y: e.target.attrs.y
        }
        setTooltip(charm)
        dispatch({ type: 'HOVER' })
    }

    const endHover = (e) => {
        setTooltip({})
    }

    const handleMouseOVer = () => {
        setHovering(true)
    }

    const handleMouseLeave = () => {
        setHovering(false)
    }

    const downloadURI = (uri, name) => {
        const link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    const stageRef = useRef();
    const handleSaveImage = event => {
        event.preventDefault();
        const dataURL = stageRef.current.toDataURL({
          mimeType: "image/jpeg",
          quality: 0,
          pixelRatio: 2
        });
        downloadURI(dataURL, quest);
      };

    const openModal = () => {
        setQuestModal(!questModal)
    }

    const openInstructions = () => {
        setInstructions(!instructions)
    }

    const openCharmsHelp = () => {
        setHelp(!help)
    }

    const url = location.href ? location.href : '';

    const getDistance = (p1, p2) => {
        return Math.sqrt(Math.pow(p2.x = p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    const getCenter = (p1, p2) => {
        return {
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2,
        };
    }

    var lastCenter = null;
    let lastDist = 0;

    return (
        <div className="bg-fadedpurple">
            <div>
                <SEO title="Charms Game Insight" />
                <div className="p-2 flex flex-wrap justify-between">
                    <div className="flex mt-2 sm:mt-0">
                        <div className="relative mx-2 px-4 py-2 border border-transparent text-sm md:text-lg leading-5 font-opensansSemiBold rounded-md text-white shadow-orange bg-charmsorange">
                            <Link to={'/'}>
                                Home
                            </Link>
                        </div>
                        <div className="text-white my-2 sm:my-0 place-self-center">
                            <span className="pl-2 font-opensansSemiBold text-lg">{quest}</span>
                        </div>
                    </div>
                    <div className="flex flex-wrap">
                        <button                   
                        className="relative mt-2 sm:mt-0 mx-2 px-4 py-2 border border-transparent text-sm md:text-lg leading-5 font-opensansSemiBold rounded-md  text-white shadow-orange bg-charmsorange"
                        onClick={openModal}>
                            Quest
                        </button>
                        <button className="relative mt-2 sm:mt-0 mx-2 px-4 py-2 border border-transparent text-sm md:text-lg leading-5 font-opensansSemiBold rounded-md text-white shadow-orange bg-charmsorange"
                            onClick={() => createCharmsResults()}
                        >
                            Roll Charms
                        </button>
                        <button 
                            className="relative mt-2 sm:mt-0 mx-2 px-4 py-2 border border-transparent text-sm md:text-lg leading-5 font-opensansSemiBold rounded-md text-white shadow-orange bg-charmsorange"
                            onClick={openInstructions}
                        >
                            How to Play
                        </button>
                    </div>
                </div>
                <div className="flex justify-center">
                <div className="text-white text-xl md:text-2xl flex items-center flex-col self-center mt-2 sm:mt-0 sm:flex-row leading-tight md:leading-normal tracking-wide font-fidopro">
                    <span className="self-start">
                        CHARMS: A Game of Insight
                    </span>
                </div>
            </div>
                <div className="flex justify-center">
                {questModal ? (
                    <>
                    <div className="bg-white md:mt-20 max-w-md shadow sm:rounded-lg overflow-x-hidden overflow-y-auto fixed z-50 outline-none focus:outline-none">
                        <Quest />
                    </div>
                    <div onClick={() => openModal()} className="z-40 bg-gray-500 opacity-0 w-full h-full fixed top-0 left-0">
                    </div>
                    </>
                    ) : null
                }
                {instructions ? (
                    <>
                    <div className="bg-white md:mt-20 max-w-md md:max-w-xl shadow sm:rounded-lg overflow-x-hidden overflow-y-auto fixed z-50 outline-none focus:outline-none">
                        <Instructions />
                    </div>
                    <div onClick={() => openInstructions()} className="z-40 bg-gray-500 opacity-0 w-full h-full fixed top-0 left-0">
                    </div>
                    </>
                    ): null
                }
                {help ? (
                    <>
                    <div onMouseEnter={handleMouseOVer} onMouseLeave={handleMouseLeave} className="w-9/12 rounded-md shadow my-6 flex-col text-right sm:rounded-lg fixed z-40 outline-none focus:outline-none">
                    <div onClick={() => set(state => !state)}>
                    {hovering ? (
                    <div className="z-50 text-xs sm:text-lg mb-2 font-opensansSemiBold text-center">
                        <span className="text-charmspurple rounded-md p-1 bg-white">Click for more meanings</span>
                    </div>
                    ) : null}
                        <a.div className="absolute w-full bg-cover" style={{ opacity: opacity.interpolate(o => 1 - o), transform }}>
                            <Img className="rounded-md bg-white" fluid={meanings1.childImageSharp.fluid} alt="An array of cards with questions for the Charms game" />
                        </a.div>
                        <a.div className="absolute w-full bg-cover" style={{ opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}>
                            <Img className="rounded-md bg-white" fluid={meanings2.childImageSharp.fluid} alt="An array of cards with questions for the Charms game" />
                        </a.div>
                    </div>
                    </div>
                    <div onClick={openCharmsHelp} className="z-30 bg-gray-500 opacity-0 w-full h-full fixed top-0 left-0">
                    </div>
                    </>
                    ) : null
                }
                <Stage
                    width={width}
                    height={width}
                    ref={stageRef}
                >
                    <Layer>
                    <Group 
                    onTouchMove={res => {
                        const stage = res.currentTarget;
                        let touch1 = res.evt.touches[0];
                        let touch2 = res.evt.touches[1];

                        if (touch1 && touch2) {
                          let dist = getDistance(
                            {
                              x: touch1.clientX,
                              y: touch1.clientY
                            },
                            {
                              x: touch2.clientX,
                              y: touch2.clientY
                            }
                          );

                          if (!lastDist) {
                            lastDist = dist;
                          }

                          if (!lastCenter) {
                            lastCenter = getCenter(                            
                              {
                                x: touch1.clientX,
                                y: touch1.clientY
                              },
                              {
                                x: touch2.clientX,
                                y: touch2.clientY
                              });
                            return;
                          }
                          
                          let newCenter = getCenter(                            
                          {
                            x: touch1.clientX,
                            y: touch1.clientY
                          },
                          {
                            x: touch2.clientX,
                            y: touch2.clientY
                          });
          
                          let scale = (stage.scaleX() * dist) / lastDist

                          let pointTo = {
                            x: (newCenter.x - stage.x()) / stage.scaleX(),
                            y: (newCenter.y - stage.y()) / stage.scaleX(),
                          };

                          let dx = newCenter.x - lastCenter.x;
                          let dy = newCenter.y - lastCenter.y;
          
                          let newPos = {
                            x: newCenter.x - pointTo.x * scale + dx,
                            y: newCenter.y - pointTo.y * scale + dy,
                          };

                          stage.scaleX(scale);
                          stage.scaleY(scale);
                          stage.position(newPos);
                          stage.getLayer().draw();
                          lastDist = dist;
                          lastCenter = newCenter;
                          res.evt.preventDefault();
                        }
                      }}
                      onTouchEnd={() => {
                        lastDist = 0;
                        lastCenter = null;
                      }}
                    >
                    <Image
                        image={charmsBoard} 
                        width={width}
                        height={width}

                    />
                        {state.results.map((charm) => (
 
                        <Image
                            width={width*0.06}
                            height={width*0.06}
                            image={charmsValues[charm.tokens]}
                            key={charm.id}
                            id={charm.id}
                            x={charm.x * scale}
                            y={charm.y * scale}
                            draggable
                            rotation={charm.rotation}
                            shadowColor="black"
                            shadowBlur={10}
                            shadowOpacity={0.6}
                            shadowOffsetX={charm.isDragging ? 10 : 5}
                            shadowOffsetY={charm.isDragging ? 10 : 5}
                            scaleX={charm.isDragging ? 1.2 : 1}
                            scaleY={charm.isDragging ? 1.2 : 1}
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                            onMouseEnter={beginHover}
                            onMouseLeave={endHover}
                            onTouchStart={beginHover}
                            onTouchEnd={endHover}
                            label={charm.tokens}
                        />

                        ))}
                        {tooltip ? 
                            <Label x={tooltip.x || -200} y={tooltip.y} >
                                <Tag pointerWidth={10} fill="#FBBE3D" />
                                <Text y={50} x={5} fontSize={18} padding={4} text={labelDictionary[tooltip.name]} fill="black" />
                            </Label>
                        : null}
                    </Group>
                    </Layer>
                </Stage>
                </div>
            </div>
            <div className="flex p-4 flex-wrap space-y-2 sm:space-y-0">
                <button
                    className="relative px-4 py-2 border border-transparent text-sm md:text-lg leading-5 font-opensansSemiBold rounded-md  text-white shadow-orange bg-charmsorange"
                    onClick={openCharmsHelp}
                    >
                        Charms and Their Meanings
                </button>
                <button
                    className="relative sm:ml-4 px-4 py-2 border border-transparent text-sm md:text-lg leading-5 font-opensansSemiBold rounded-md  text-white shadow-orange bg-charmsorange"
                    onClick={handleSaveImage}>
                        Download Roll as Image
                </button>
                <div className="ml-2 sm:ml-2">
                <Twitter solid small link={url} />
                <Facebook solid small link={url} />
                </div>
            </div>
        </div>
    )
}

export default CharmsRoomTemplate