/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCharmsRoom = /* GraphQL */ `
  subscription OnCreateCharmsRoom {
    onCreateCharmsRoom {
      id
      question
      ttl
      charmsResults {
        items {
          id
          roomId
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCharmsRoom = /* GraphQL */ `
  subscription OnUpdateCharmsRoom {
    onUpdateCharmsRoom {
      id
      question
      ttl
      charmsResults {
        items {
          id
          roomId
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCharmsRoom = /* GraphQL */ `
  subscription OnDeleteCharmsRoom {
    onDeleteCharmsRoom {
      id
      question
      ttl
      charmsResults {
        items {
          id
          roomId
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCharmsResults = /* GraphQL */ `
  subscription OnCreateCharmsResults {
    onCreateCharmsResults {
      id
      roomId
      ttl
      results {
        id
        x
        y
        rotation
        isDragging
        tokens
      }
      charmsRoom {
        id
        question
        ttl
        charmsResults {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCharmsResults = /* GraphQL */ `
  subscription OnUpdateCharmsResults {
    onUpdateCharmsResults {
      id
      roomId
      ttl
      results {
        id
        x
        y
        rotation
        isDragging
        tokens
      }
      charmsRoom {
        id
        question
        ttl
        charmsResults {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCharmsResults = /* GraphQL */ `
  subscription OnDeleteCharmsResults {
    onDeleteCharmsResults {
      id
      roomId
      ttl
      results {
        id
        x
        y
        rotation
        isDragging
        tokens
      }
      charmsRoom {
        id
        question
        ttl
        charmsResults {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
