import React, { useContext, useReducer, useState } from 'react'
import { QuestContext } from '../context/questContext'

const Quest = () => {
    const { setQuestModal, setQuest } = useContext(QuestContext)
    const [popup, setPopup] = useState(false)

    const initialState = {
        form: { name: '' }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    function reducer(state, action) {
      switch(action.type) {
        case 'SET_INPUT':
          return { ...state, form: { ...state.form, [action.name]: action.value }}
        case 'RESET_FORM':
          return { ...state, form: initialState.form }
        case 'ERROR':
          return { ...state, loading: false, error: true }
        default:
          return state
      }
    }

    const onChange = (e) => {
        dispatch({ type: 'SET_INPUT', name: e.target.name, value: e.target.value })
    }

    const save = () => {
      if (!state.form.name) {
        setPopup(true)
      } else {
        setQuest(state.form.name)
        setQuestModal(false)
        setPopup(false)
      }
    }

    return(
        <div className="px-4 py-5 sm:p-6 border-bottompurple border-4">
        <div className="flex justify-start">
          <h3 className=" place-self-center text-lg leading-6 font-medium text-gray-900">
              Enter Quest:
          </h3>
        </div>
        <div className="mt-5 sm:flex sm:items-center">
            <div className="max-w-xs w-full">
            <label htmlFor="name" className="sr-only">Quest</label>
            <div className="relative rounded-md shadow-sm">
                <input 
                    id="name"
                    name="name"
                    className="form-input p-2 text-black block w-full sm:text-sm sm:leading-5"
                    onChange={onChange}
                    value={state.form.name}
                    maxLength="125"
                />
            </div>
        </div>
        <span className="mt-3 inline-flex rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
        <button onClick={() => save()} 
                className="relative px-4 py-2 border border-transparent text-sm md:text-lg leading-5 font-opensansSemiBold rounded-md text-white shadow-orange bg-charmsorange">
            Enter
        </button>
        </span>
        </div>
        {popup === true ? <p className="text-red-600 pt-2 -mb-2 font-opensansSemiBold">Please enter a Quest to continue</p> : null}
        </div>
    );
  }

export default Quest;
