import React from 'react'

const Instructions = () => {
    return(
        <div className="text-base font-opensansSemiBold px-4 py-5 sm:p-6 border-bottompurple border-4 overflow-auto h-64">
            <h2 className="text-xl">
                WELCOME to CHARMS ONLINE!
            </h2>
            <p>
            Roll the digital Charms to spark insightful dialogues, writing activities, or brainstorming sessions on topics of your choice. Play on your own, during video conference calls, or share your quests via text, email, or social media. Charms Online works best on bigger screens—ipads or larger monitors.
            </p>
            <h2 className="text-xl">
                HOW TO PLAY:
            </h2>
            <ol className="list-decimal ml-4">
                <li>
                    Click <span className="font-opensansExtraBold">Quest</span>, type in your or your group's question/quest (one you really want answers to),
                    and click <span className="font-opensansExtraBold">Enter</span>. Use quests from the <a className="underline" href="https://www.shop.studio9inc.com/Charms-A-Game-of-Insight-S9G10012.htm">CHARMS: A Game of Insight</a> board game, <a className="underline" href="https://www.kickstarter.com/projects/studio9games/charms-quests-for-antiracism?ref=user_menu">Quests for Antiracism</a> expansion, or one of your own. Quester(s) should modify it to match your needs (making it as specific as possible).
                </li>
                <li>
                    Click to <span className="font-opensansExtraBold">Roll Charms</span>.
                </li>
                <li>
                Click and drag onto the table any “out of bounds” charms—pieces that landed off the board or on a dividing line between any sections of the board. If less than two pieces remain, click <span className="font-opensansExtraBold">Roll Charms</span> again.
                </li>
                <li>
                Review “in play” charm meanings by hovering over each piece. Or click <span className="font-opensansExtraBold">Charms and Their Meanings</span> to see front and backsides of key card. (It may help to type relevant meanings into video chat, text, or email for all to see.)
                </li>
                <li>
                Everyone tries to discover the most insightful answers for you or your group’s quest—inspired by the charms in play and the areas where they remain on the board. Take turns sharing (verbal, written, or typed) answers with the group.
                </li>
                <h2 className="text-xl -ml-5">
                    Optional:
                </h2>
                <li>
                Quester can select their favorite answer as the winner.
                </li>
                <li>
                <span className="font-opensansExtraBold">Click Download Roll as Image</span> to save a Charms roll that can also be shared in a video chat, on social media, or via text or email.
                </li>
                <li>
                Take turns being the Quester. See who can discover the most incredible insights.
                </li>
            </ol>
            <h2 className="text-xl -ml-1">
                TIPS AND SUGGESTIONS:
            </h2>
            <ul className="list-disc ml-4">
                <li>
                Set time limits as needed so everyone has a chance to share.
                </li>
                <li>
                This is a creative problem-solving activity. Encourage players to keep an open mind about the gameplay and process, and to try their best to give and gain the most helpful insights. If Quester or group decides there aren’t any insightful answers, they may choose not to select a winning answer for that turn.
                </li>
                <li>
                A Quester may choose to share why they’ve selected a particular answer as their favorite.
                </li>
            </ul>
            <h2 className="text-xl -ml-1">
                <a className="underline" href="https://www.kickstarter.com/projects/studio9games/charms-quests-for-antiracism?ref=user_menu">QUESTS FOR ANTIRACISM: Click here to learn more</a>
            </h2>
                <p>
                This expansion includes questions for individuals and groups sincerely interested in striving towards antiracism. In the context of Charms, even the heaviest of quests can become lighter.
                 </p>
                 <p>
                 Studio 9 Games and our collaborators view racism as the most vital and challenging issue facing America. We understand that everyone belongs to one wonderfully diverse human family, we're all created noble, and we all deserve equal rights and fair opportunities. We also know that our collective well-being, peace, and security are dependent upon rooting out racism and prejudices, and that the sincere attention and sustained participation and action of all peoples are what is required to succeed in its elimination.
                 </p>
                 <ul className="list-disc ml-4">
                    <li>
                    While having conversations about racism is a necessary step towards creating justice and liberty for all, talking about it can be challenging and bring up uncomfortable feelings. It’s up to each player (or discuss as a group beforehand) to find productive ways to manage any feelings of discomfort.
                    </li>
                    <li>
                    It may help for players to remember that while racism is embedded in the fabric of our society and systems, we all have the ability to learn, grow, transform, and create positive change. Encourage everyone to find their courage, care, compassion, and to strive towards antiracism.
                    </li>
                    <li>
                    Consider playing in a diverse group, or with players of the same race—depending on your needs or goals.
                    </li>
                    <li>
                    Before playing, it may be helpful to center and connect the group by doing a brief activity, such as a breathing exercise, playing uplifting music, or sharing inspiring quotes.
                    </li>
                    <li>
                    Help each other explore the meaning of complex words and concepts that come up, like antiracism (i.e. ideas, actions, or policies that work against racism—towards racial justice and equality for all.)
                    </li>
                 </ul>
        </div>
    );
  }

export default Instructions;